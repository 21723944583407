.template-value-chunk {
  border: 1px solid black;
  padding: 5px;
}

.template-value-chunk:hover {
  cursor: pointer;
}

.template-value-chunk--selected {
  background-color: lightgray;
}
