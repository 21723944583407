/* 
  This CSS is adapted from Polaris' example for adding a collection select filter for a table:
  https://cloudscape.aws.dev/components/collection-select-filter/
  Polaris CSS source code: https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-3.0/--/src/styles/table-select.scss
*/

.input-container {
  display: flex;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 10;
  margin-right: 0;
  margin-bottom: -1rem;

  > *:not(:empty) {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.input-filter {
  order: 0;
  flex-grow: 6;
  width: auto;
  max-width: 648px; // Matches max-width of Polaris PropertyFilter input box
}

.select-filter {
  max-width: 180px;
  flex-grow: 4;
  width: auto;
}

@media (max-width: 768px) {
  .input-container {
    margin-right: -1rem;
  }

  .select-filter {
    max-width: none;
  }

  .input-filter {
    width: 100%;
    max-width: none;
  }
}
