div.section {
    &:first-of-type {
        margin-top: 30px;
    }
    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

body {
    margin: 0px;
}

[data-layout-id="mainNavigation"]{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

[data-layout-id="appLayout"]{
    position: relative;
    z-index: 0;
}

.pdf__container{
    position: relative;
    background: darkgray;
    overflow: hidden;
}

.pdf__content{
    touch-actions: none;
    cursor: pointer;
}

.pdf__controls{
    background: rgba(black, 50%);
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 1rem;
    gap: 1rem;
}

.subsection__header{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
    * {
        margin: 0;
    }
}

fieldset {
    border: 2px solid #d5dbdb;
    border-radius: 2px;
}

legend {
    font-weight: 600;
}

.whitespace--pre{
    white-space: pre;
}
